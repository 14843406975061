import {
	createContext,
	forwardRef,
	HTMLAttributes,
	ReactElement,
	ReactNode,
	useContext,
	useEffect,
	useRef
} from "react";
import { ListChildComponentProps, VariableSizeList } from "react-window";
import { autocompleteClasses, ListSubheader, Popper, Typography, useMediaQuery } from "@mui/material";
import { useTheme } from "@emotion/react";
import styled from "@emotion/styled";

const LISTBOX_PADDING = 8; // px

function renderRow(props: ListChildComponentProps) {
	const { data, index, style } = props;
	const dataSet = data[index];
	const inlineStyle = {
		...style,
		top: (style.top as number) + LISTBOX_PADDING
	};

	if (dataSet.hasOwnProperty("group")) {
		return (
			<ListSubheader
				key={dataSet.key}
				component="div"
				style={inlineStyle}
			>
				{dataSet.group}
			</ListSubheader>
		);
	}

	const { key, ...optionProps } = dataSet[0];

	return (
		<Typography
			key={key}
			component="li"
			{...optionProps}
			noWrap
			style={inlineStyle}
		>
			{dataSet[1]}
		</Typography>
	);
}

const OuterElementContext = createContext({});

// eslint-disable-next-line react/display-name
const OuterElementType = forwardRef<HTMLDivElement>((props, ref) => {
	const outerProps = useContext(OuterElementContext);
	return (
		<div
			style={{ width: "100%" }}
			ref={ref}
			{...props}
			{...outerProps}
		/>
	);
});

function useResetCache(data: number) {
	const ref = useRef<VariableSizeList>(null);
	useEffect(() => {
		if (ref.current != null) {
			ref.current.resetAfterIndex(0, true);
		}
	}, [data]);
	return ref;
}

// Adapter for react-window
const VirtualizedList = forwardRef<HTMLDivElement, HTMLAttributes<HTMLElement>>(function ListboxComponent(props, ref) {
	const { children, ...other } = props;
	const itemData: ReactElement<unknown>[] = [];
	(children as ReactElement<unknown>[]).forEach(
		(
			item: ReactElement<unknown> & {
				children?: ReactElement<unknown>[];
			}
		) => {
			itemData.push(item);
			itemData.push(...(item.children || []));
		}
	);

	const theme = useTheme();
	const smUp = useMediaQuery(theme.breakpoints.up("sm"), {
		noSsr: true
	});
	const itemCount = itemData.length;
	const itemSize = smUp ? 36 : 48;

	const getChildSize = (child: ReactElement<unknown> | undefined) => {
		if (child?.hasOwnProperty("group")) {
			return 48;
		}

		return itemSize;
	};

	const getHeight = () => {
		if (itemCount > 8) {
			return 8 * itemSize;
		}
		return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
	};

	const gridRef = useResetCache(itemCount);

	return (
		<div ref={ref}>
			<OuterElementContext.Provider value={other}>
				<VariableSizeList
					itemData={itemData}
					style={{ overflowX: "hidden" }}
					height={getHeight() + 2 * LISTBOX_PADDING}
					width="100%"
					ref={gridRef}
					outerElementType={OuterElementType}
					innerElementType="ul"
					itemSize={(index) => getChildSize(itemData[index])}
					overscanCount={10}
					itemCount={itemCount}
				>
					{renderRow}
				</VariableSizeList>
			</OuterElementContext.Provider>
		</div>
	);
});

const StyledPopper = styled(Popper)({
	[`& .${autocompleteClasses.listbox}`]: {
		boxSizing: "border-box",
		"& ul": {
			padding: 0,
			margin: 0
		}
	}
});

export const muiVirtualizedProps = {
	renderOption: (props: any, option: any, state: any) => [props, option, state.index] as ReactNode,
	ListboxComponent: VirtualizedList,
	PopperComponent: StyledPopper
};

export default VirtualizedList;
