import { useState, KeyboardEvent, MouseEvent } from "react";
import { Grid, Box, Divider } from "@mui/material";
import styles from "pages/order-details-items-styles.module.css";
import { dateFormat } from "utils/date";
import {
	OrderDetailFlyoutBackorderPartsAlertText,
	OrderDetailFlyoutBackorderCabinetAlertTextPartOne,
	OrderDetailFlyoutBackorderCabinetAlertTextPartTwo,
	OrderDetailFlyoutHeader,
	UnavailableDataPlaceholderText,
	OrderDetailFlyoutAlertHeaderParts,
	OrderDetailFlyoutAlertHeaderCabinet,
	OrderDetailFlyoutQualityBackorderedLabel,
	OrderDetailFlyoutArrivalDateLabel,
	FlyoutDoorStyleLabel,
	OrderDetailFlyoutQuantityLabel,
	OrderDetailFlyoutListPriceLabel,
	OrderDetailFlyoutExtendedPriceLabel,
	FlyoutModificationsLabel,
	FlyoutFinishNameLabel,
	FlyoutStandardDimensionsLabel,
	OrderDetailFlyoutDepthLabel,
	OrderDetailFlyoutWidthLabel,
	OrderDetailFlyoutHeightLabel,
	FlyoutConstructionLabel,
	FlyoutHingeLabel,
	OrderDetailFlyoutModificationsDetailsLabel,
	OrderDetailFlyoutModificationsValueLabel,
	OrderDetailFlyoutModificationsPriceLabel,
	FlyoutItemNumberLabel
} from "constants/text";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import CloseIcon from "@mui/icons-material/Close";
import { toFormattedPrice } from "utils/string";
import { brandCheck } from "utils/order";
import StyledSummaryCardLogo from "styles/StyledComponents/StyledSummaryCardLogo";
import {
	ItemsOnBackorderButton,
	OrderDetailsFlyoutContainerAlertPartsTableHeader,
	OrderDetailsFlyoutContainerAlertPartsTableHeaderLabel,
	OrderDetailsFlyoutContainerAlertPartsTableBody,
	OrderDetailsFlyoutContainerAlertPartsTableBodyValue,
	OrderDetailsFlyoutResultBackground,
	OrderDetailsFlyoutShadowBlur
} from "./OrderDetailsLineItemFlyout.styles";

const OrderDetailsLineItemFlyout = ({
	measurements,
	description,
	doorStyle,
	itemNumber,
	finish,
	logo,
	modifications,
	listPrice,
	extendedPrice,
	quantityOrdered,
	quantityBackordered,
	arrivalDate,
	sku,
	partsQuantityBackordered,
	partInfoPartCount,
	construction,
	hinge,
	parts,
	orderType,
	closeDrawer,
	lineItemId
}: OrderDetailProps) => {
	const [toggleBackorderGrid, setToggleBackorderGrid] = useState(false);

	const togglePartsGrid = () => {
		setToggleBackorderGrid((prev) => !prev);
	};

	const modificationsMap = modifications.map((modification: any) => modification.details);
	const inches = measurements?.units?.toUpperCase() === "IN";

	const measurementsValue = (measurement: number) => {
		if (measurement === 0 || !measurement) {
			return UnavailableDataPlaceholderText;
		} else {
			return measurement?.toString().replace(/\.00$/, "");
		}
	};

	return (
		<div
			className={styles.orderDetailsFlyoutContainer}
			data-testid="order-details-line-item-flyout-container"
		>
			<div className={styles.orderDetailsFlyoutHeader}>
				<h5
					data-testid="order-details-flyout-header"
					style={
						orderType !== "BACKORDER" || partsQuantityBackordered || quantityBackordered !== 0
							? { margin: 0, paddingBottom: "1rem" }
							: { marginBottom: "1rem", marginTop: 0 }
					}
				>
					{OrderDetailFlyoutHeader}
				</h5>
				<CloseIcon
					onClick={closeDrawer}
					sx={{ cursor: "pointer" }}
				/>
			</div>

			<div className={styles.orderDetailsScrollableBlock}>
				{(partsQuantityBackordered || quantityBackordered !== 0) && orderType !== "BACKORDER" ? (
					<OrderDetailsFlyoutShadowBlur>
						<div className={styles.orderDetailsFlyoutAlert}>
							<img
								src="/assets/BackorderIcon.svg"
								alt="Order Details Line Item Backorder Logo"
								data-testid="order-detail-line-items-flyout-backorder-logo"
							/>
							<h6 data-testid="order-details-line-item-flyout-backorder-header">
								{quantityBackordered !== 0
									? OrderDetailFlyoutAlertHeaderCabinet
									: OrderDetailFlyoutAlertHeaderParts}
							</h6>
							<span
								style={{ lineHeight: "1.15rem" }}
								data-testid="order-details-line-item-flyout-backorder-description"
							>
								{quantityBackordered !== 0
									? `${OrderDetailFlyoutBackorderCabinetAlertTextPartOne} (${description}) ${OrderDetailFlyoutBackorderCabinetAlertTextPartTwo}`
									: OrderDetailFlyoutBackorderPartsAlertText}
							</span>
							<br />
							{quantityBackordered !== 0 ? (
								<div
									style={{ marginTop: "0.5rem" }}
									data-testid="order-details-line-item-flyout-backorder-details"
								>
									{quantityBackordered && (
										<span>
											{OrderDetailFlyoutQualityBackorderedLabel}: {quantityBackordered}
										</span>
									)}

									<br />
									{arrivalDate && (
										<span>
											{OrderDetailFlyoutArrivalDateLabel}: {dateFormat(arrivalDate)}
										</span>
									)}
								</div>
							) : (
								<ItemsOnBackorderButton
									variant="outlined"
									data-testid="order-details-line-item-flyout-backorder-parts-button"
									onClick={() => togglePartsGrid()}
								>
									{OrderDetailFlyoutAlertHeaderParts.toUpperCase()} ({partInfoPartCount})
									{toggleBackorderGrid ? (
										<RemoveIcon sx={{ fontSize: 18, marginLeft: 1 }} />
									) : (
										<AddIcon sx={{ fontSize: 18, marginLeft: 1 }} />
									)}
								</ItemsOnBackorderButton>
							)}
						</div>
						{quantityBackordered === 0 && (
							<OrderDetailsFlyoutResultBackground>
								{toggleBackorderGrid && (
									<Grid
										container
										style={{
											padding: 8
										}}
									>
										{parts.map((part: any) => (
											<Grid
												container
												className={styles.orderDetailsFlyoutContainerAlertPartsGrid}
												key={part.number}
											>
												<OrderDetailsFlyoutContainerAlertPartsTableHeader
													item
													className="table-header"
												>
													<OrderDetailsFlyoutContainerAlertPartsTableHeaderLabel item>
														Part
													</OrderDetailsFlyoutContainerAlertPartsTableHeaderLabel>
													<OrderDetailsFlyoutContainerAlertPartsTableHeaderLabel item>
														Part #
													</OrderDetailsFlyoutContainerAlertPartsTableHeaderLabel>
													<OrderDetailsFlyoutContainerAlertPartsTableHeaderLabel item>
														Qty
													</OrderDetailsFlyoutContainerAlertPartsTableHeaderLabel>
												</OrderDetailsFlyoutContainerAlertPartsTableHeader>
												<OrderDetailsFlyoutContainerAlertPartsTableBody
													item
													key={part.number}
												>
													<OrderDetailsFlyoutContainerAlertPartsTableBodyValue
														item
														className="body1"
														data-testid="order-details-line-item-flyout-partInfo-description"
													>
														{part.description ?? UnavailableDataPlaceholderText}
													</OrderDetailsFlyoutContainerAlertPartsTableBodyValue>
													<OrderDetailsFlyoutContainerAlertPartsTableBodyValue
														item
														className="body1"
														data-testid="order-details-line-item-flyout-partInfo-number"
													>
														{part.number ?? UnavailableDataPlaceholderText}
													</OrderDetailsFlyoutContainerAlertPartsTableBodyValue>
													<OrderDetailsFlyoutContainerAlertPartsTableBodyValue
														item
														className="body1"
														data-testid="order-details-line-item-flyout-partInfo-quantity"
													>
														{part.quantity ?? UnavailableDataPlaceholderText}
													</OrderDetailsFlyoutContainerAlertPartsTableBodyValue>
												</OrderDetailsFlyoutContainerAlertPartsTableBody>
											</Grid>
										))}
									</Grid>
								)}
							</OrderDetailsFlyoutResultBackground>
						)}
					</OrderDetailsFlyoutShadowBlur>
				) : undefined}

				<h6
					className={styles.orderDetailsFlyoutLineItemId}
					data-testid="order-detail-line-items-flyout-sku"
				>
					{sku}
				</h6>
				<span
					className="body1"
					data-testid="order-detail-line-items-flyout-description"
				>
					{description}
				</span>

				<div className={styles.orderDetailsFlyoutContentBlock}>
					<StyledSummaryCardLogo
						data-testid="order-line-items-flyout-logo"
						parentBrand={logo}
						src={
							brandCheck(logo)
								? `/assets/manufacture_logos/${logo}.png`
								: "/assets/tandem_logos/cwg_logo.png"
						}
						alt={brandCheck(logo) ? logo : "CabinetworksGroup Logo"}
					/>
					<br />
					<span className="subtitle2">{FlyoutItemNumberLabel}</span>
					<span
						className="body2"
						data-testid="order-details-line-item-flyout-item-number"
					>
						{itemNumber || UnavailableDataPlaceholderText}
					</span>
					<br />
					<span className="subtitle2">{FlyoutDoorStyleLabel}</span>
					<span
						className="body2"
						data-testid="order-details-line-item-flyout-door-style"
					>
						{doorStyle ?? UnavailableDataPlaceholderText}
					</span>
					<br />
					<span className="subtitle2">{FlyoutConstructionLabel}</span>
					<span
						className="body2"
						data-testid="order-details-line-item-flyout-construction"
					>
						{construction ?? UnavailableDataPlaceholderText}
					</span>
					<br />
					<span className="subtitle2">{FlyoutHingeLabel}</span>
					<span
						className="body2"
						data-testid="order-details-line-item-flyout-hinge"
					>
						{hinge ?? UnavailableDataPlaceholderText}
					</span>
					<br />
					<span className="subtitle2">{FlyoutFinishNameLabel}</span>
					<span
						className="body2"
						data-testid="order-details-line-item-flyout-finish"
					>
						{finish ?? UnavailableDataPlaceholderText}
					</span>
				</div>

				<div className={styles.orderDetailsFlyoutContentBlock}>
					<label className="subtitle1">{FlyoutStandardDimensionsLabel}</label>
					<br />
					<span className="subtitle2">{OrderDetailFlyoutWidthLabel}</span>
					<span
						className="body2"
						data-testid="order-details-line-item-flyout-width"
					>
						{measurementsValue(measurements?.width)}
						{measurements.width !== 0 && inches && " inches"}
					</span>
					<br />
					<span className="subtitle2">{OrderDetailFlyoutDepthLabel}</span>
					<span
						className="body2"
						data-testid="order-details-line-item-flyout-depth"
					>
						{measurementsValue(measurements?.depth)}
						{measurements.depth !== 0 && inches && " inches"}
					</span>
					<br />
					<span className="subtitle2">{OrderDetailFlyoutHeightLabel}</span>
					<span
						className="body2"
						data-testid="order-details-line-item-flyout-height"
					>
						{measurementsValue(measurements?.height)}
						{measurements.height !== 0 && inches && " inches"}
					</span>
				</div>

				<div className={styles.orderDetailsFlyoutContentBlock}>
					<label className="subtitle1">{FlyoutModificationsLabel}</label>
					<br />
					{modifications.map((modification: any) => (
						<div key={modification.details}>
							<span className="subtitle2">{OrderDetailFlyoutModificationsDetailsLabel}</span>
							<span
								className="body2"
								data-testid="order-details-line-item-flyout-modification-details"
							>
								{modification.details ? modification.details : UnavailableDataPlaceholderText}
							</span>
							<br />
							<span className="subtitle2">{OrderDetailFlyoutModificationsPriceLabel}</span>
							<span
								className="body2"
								data-testid="order-details-line-item-flyout-modification-price"
							>
								{toFormattedPrice(modification.price)}
							</span>
							<br />
							<span className="subtitle2">{OrderDetailFlyoutModificationsValueLabel}</span>
							<span
								className="body2"
								data-testid="order-details-line-item-flyout-modification-value"
							>
								{modification.value ? modification.value : UnavailableDataPlaceholderText}
							</span>
						</div>
					))}
					{modificationsMap.length === 0 && <span className="body2">{UnavailableDataPlaceholderText}</span>}
				</div>

				<div className={styles.orderDetailsFlyoutContentBlock}>
					<label className="subtitle1">{OrderDetailFlyoutQuantityLabel}</label>
					<br />
					<span
						className="body2"
						data-testid="order-details-line-item-flyout-quantity-ordered"
					>
						{quantityOrdered ?? UnavailableDataPlaceholderText}
					</span>
				</div>
			</div>

			<Grid
				container
				className={styles.orderDetailsFlyoutLastContent}
			>
				<Grid container>
					<Grid
						item
						xs={6}
					>
						<span className="subtitle2">{OrderDetailFlyoutListPriceLabel}</span>
					</Grid>
					<Grid
						item
						xs={6}
					>
						<Box
							display="flex"
							justifyContent="flex-end"
						>
							<span
								className="subtitle2"
								data-testid="order-details-line-item-flyout-list-price"
							>
								{toFormattedPrice(listPrice)}
							</span>
						</Box>
					</Grid>
				</Grid>
				<Grid container>
					<Divider />
					<Grid
						item
						xs={6}
					>
						<span className="subtitle1">{OrderDetailFlyoutExtendedPriceLabel}</span>
					</Grid>
					<Grid
						item
						xs={6}
					>
						<Box
							display="flex"
							justifyContent="flex-end"
						>
							<span
								className="subtitle1"
								data-testid="order-details-line-item-flyout-extended-price"
							>
								{toFormattedPrice(extendedPrice)}
							</span>
						</Box>
					</Grid>
				</Grid>
			</Grid>
		</div>
	);
};

export interface OrderDetailProps {
	description: string | null;
	itemNumber: any;
	doorStyle: any;
	finish: any;
	logo: any;
	modifications: any;
	measurements: any;
	listPrice: any;
	extendedPrice: any;
	quantityOrdered: any;
	quantityBackordered: any;
	arrivalDate: any;
	sku: any;
	partsQuantityBackordered: any;
	partInfoPartCount: any;
	construction: any;
	hinge: any;
	parts: any;
	orderType: any;
	name: any;
	closeDrawer: (event: KeyboardEvent | MouseEvent) => void;
	replaceableParts?: any;
	lineItemNumber: any;
	lineItemId: string;
}
export default OrderDetailsLineItemFlyout;
